<template>
    <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="500px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :modal-append-to-body="true"
            :append-to-body="true"
            :before-close="handleClose">
        <el-form size="small" :model="inputForm" :rules="dataRule" ref="inputForm" label-width="120px">
            <el-form-item label="模板名称：" prop="resourceName">
                <el-input v-model.trim="inputForm.resourceName" clearable maxlength="20"
                          placeholder="请输入模板名称(限20字)"></el-input>
            </el-form-item>
            <el-form-item label="界面高度：" prop="interfaceHeight">
                <el-input v-model.trim="inputForm.interfaceHeight" clearable maxlength="20"
                          placeholder="请输入界面高度(限20字)"></el-input>
            </el-form-item>
            <el-form-item label="界面宽度：" prop="interfaceWidth">
                <el-input v-model.trim="inputForm.interfaceWidth" clearable maxlength="20"
                          placeholder="请输入界面宽度(限20字)"></el-input>
            </el-form-item>
            <el-form-item label="布局方式：" prop="layoutMethod">
                <el-select v-model="inputForm.layoutMethod" placeholder="请选择元数据模板" style="width: 100%">
                    <el-option
                            v-for="item in layoutMethodList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="模板描述：" prop="description">
                <el-input v-model.trim="inputForm.description" clearable maxlength="20"
                          placeholder="请输入模板描述(限20字)"></el-input>
            </el-form-item>
            <el-form-item label="类型：" prop="type">
                <el-select v-model="inputForm.type" placeholder="请选择元数据模板" style="width: 100%">
                    <el-option
                            v-for="item in this.$dictUtils.getDictList('resource_type')"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="应用范围：" prop="scope">
                <el-input v-model.trim="inputForm.scope" clearable maxlength="20"
                          placeholder="请输入应用范围(限50字)"></el-input>
            </el-form-item>
            <el-form-item label="启用状态：" prop="isEnable">
                <el-switch
                        v-model="inputForm.isEnable"
                        :active-value="'0'"
                        :inactive-value="'1'"
                >
                </el-switch>
            </el-form-item>
        </el-form>
        <div class="text_center">
            <el-button size="small" @click="handleClose()">取消</el-button>
            <el-button size="small" type="primary" @click="suresData()" v-no-more-click>保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "addTemplate",
    data() {
        return {
            title: '新增元数据模板',
            dialogVisible: false,
            inputForm: {
                resourceName: '',
                interfaceHeight: '',
                interfaceWidth: '',
                layoutMethod: '',
                description: '',
                type: '',
                scope: '',
                isEnable: '0',
            },
            dataRule: {
                resourceName: [
                    {required: true, message: '请输入模板名称', trigger: 'blur'}
                ],
            },
            layoutMethodList: [
                {
                    value: '0',
                    label: '上下'
                },
                {
                    value: '1',
                    label: '左右'
                },
            ],

            butType: '',//0新增 1详情 2修改
        }
    },

    methods: {
        init(num, row) {
            this.butType = num
            if (num != 0) {
                if (num == 2) {
                    this.title = '修改元数据模板'
                    this.$axios(this.api.original.originalGetById, {
                        id: row.id,
                    }, "get").then((data) => {
                        if (data && data.status) {
                            this.inputForm = data.data
                            this.dialogVisible = true
                        }
                    });
                }
            } else {
                this.dialogVisible = true
            }
        },

        suresData() {
            this.$refs.inputForm.validate((valid) => {
                if (valid) {
                    if (this.inputForm.id) {
                        this.$axios(this.api.original.originalUpdateById, {
                            ...this.inputForm,
                        }, "put").then((data) => {
                            if (data && data.status) {
                                this.$message.success('修改成功')
                                this.$emit('refreshList')
                                this.handleClose()
                            } else {
                                this.$message.error(res.msg)
                            }
                        });
                    } else {

                        this.$axios(this.api.original.originalSave, {
                            ...this.inputForm,
                        }, "get").then((data) => {
                            if (data && data.status) {
                                this.$message.success('保存成功')
                                this.$emit('refreshList')
                                this.handleClose()
                            } else {
                                this.$message.error(res.msg)
                            }
                        });

                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        handleClose() {
            this.$refs.inputForm.resetFields()
            this.dialogVisible = false
        },
    },
}
</script>

<style scoped>

</style>